<template>
	<layout-default>
		<div>
			<div>
				<div>
					<b-button
						class="is-danger margin-top-30"
						style="width: 400px"
						:disabled="isResetBtnDisabled"
						@click="onReset"
					>
						Reset previous year's balance & cost
					</b-button>
				</div>
				<div class="text-bold text-red margin-top-5 margin-bottom-20">
					... reset previous year's balance & cost values
				</div>
			</div>
			<div>
				<b-button
					class="is-info margin-top-30"
					style="width: 400px"
					:disabled="isFetchBtnDisabled"
					@click="onFetchInventoryValues"
				>
					Fetch inventory balance & cost from Database
				</b-button>
			</div>
			<div class="text-bold text-blue-600 margin-top-5 margin-bottom-20">
				... fetch inventory balance & cost from database
			</div>
			<div>
				<b-button
					class="is-success margin-top-30"
					style="width: 400px"
					:disabled="isSetBtnDisabled"
					@click="onSetPreviousYear"
				>
					Set previous year's balance & cost from inventory
				</b-button>
				<div class="text-bold text-green margin-top-5 margin-bottom-20">
					... set previous year's balance & cost from inventory
				</div>
			</div>
			<div>
				<div>
					<b-button
						class="is-danger margin-top-30"
						style="width: 400px"
						:disabled="isReset2BtnDisabled"
						@click="onReset2"
					>
						Reset outstanding balance & cost
					</b-button>
				</div>
				<div class="text-bold text-red margin-top-5 margin-bottom-20">
					... reset outstanding balance & cost for new year
				</div>
			</div>
			<div>
				<b-button
					class="is-info margin-top-30"
					style="width: 400px"
					:disabled="isFetch2BtnDisabled"
					@click="onFetchPreviousYearsValues"
				>
					Fetch previous year's balance & cost from Database
				</b-button>
			</div>
			<div class="text-bold text-blue-600 margin-top-5 margin-bottom-20">
				... fetch previous year's balance & cost from database
			</div>
			<div>
				<b-button
					class="is-success margin-top-30"
					style="width: 400px"
					:disabled="isSet2BtnDisabled"
					@click="onSetOutstanding"
				>
					Set outstanding balance & cost from previous year
				</b-button>
				<div class="text-bold text-green margin-top-5 margin-bottom-20">
					... set outstanding balance & cost from previous year
				</div>
			</div>
		</div>
	</layout-default>
</template>
<script>
import ResetPreviousYearsValuesMutation from '@/_srcv2/views/pages/startup/components/set-outstanding-values/graphql/ResetPreviousYearsValuesMutation.graphql'
import GetInventoryBalanceStockValueQuery from '@/_srcv2/views/pages/startup/components/set-outstanding-values/graphql/GetInventoryBalanceStockValueQuery.graphql'
import SetPreviousYearsAmountsMutation from '@/_srcv2/views/pages/startup/components/set-outstanding-values/graphql/SetPreviousYearsAmountsMutation.graphql'
// -------------------------------------------------------------------------
import ResetOutstandingValuesMutation from '@/_srcv2/views/pages/startup/components/set-outstanding-values/graphql/ResetOutstandingValuesMutation.graphql'
import GetPreviousYearsValuesQuery from '@/_srcv2/views/pages/startup/components/set-outstanding-values/graphql/GetPreviousYearsValuesQuery.graphql'
import SetOutstandingValuesMutation from '@/_srcv2/views/pages/startup/components/set-outstanding-values/graphql/SetOutstandingValuesMutation.graphql'
// ---------------------------------------------------------------------------
import { useMutation, useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import { ref, reactive, onMounted } from '@vue/composition-api'
import Router from '@/router'

export default {
	name: 'SetOutstandingValuesFromInventory',
	setup() {
		const isResetBtnDisabled = ref(false)
		const isFetchBtnDisabled = ref(true)
		const isSetBtnDisabled = ref(true)
		const isReset2BtnDisabled = ref(true)
		const isFetch2BtnDisabled = ref(true)
		const isSet2BtnDisabled = ref(true)
		onMounted(() => {
			isResetBtnDisabled.value = false
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = true
			isReset2BtnDisabled.value = true
			isFetch2BtnDisabled.value = true
			isSet2BtnDisabled.value = true
		})
		// * -----------------------------------------------------------------------------------------
		const { mutate: resetMutation, onDone: onDoneReset } = useMutation(
			ResetPreviousYearsValuesMutation,
			() => ({
				variables: {
					company_id: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		onDoneReset(() => alert("Pervious Year's values are reset"))
		// ! -----------------------------------------------------------------------------------------
		const onReset = () => {
			resetMutation()
			isResetBtnDisabled.value = true
			isFetchBtnDisabled.value = false
			isSetBtnDisabled.value = true
			isReset2BtnDisabled.value = true
			isFetch2BtnDisabled.value = true
			isSet2BtnDisabled.value = true
		}
		// * -------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchInventoryBalanceStock } = useQuery(
			GetInventoryBalanceStockValueQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// ! -----------------------------------------------------------------------------------------
		const dataArray = ref([])
		const onFetchInventoryValues = () => {
			refetchInventoryBalanceStock().then((result) => {
				console.log('result.data.stock', result.data.stock)
				dataArray.value = result.data.stock
			})
			isResetBtnDisabled.value = true
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = false
			isReset2BtnDisabled.value = true
			isFetch2BtnDisabled.value = true
			isSet2BtnDisabled.value = true
		}
		// * -----------------------------------------------------------------------------------------
		const mutationVariables = reactive({
			company_id: Store.getters.getUserCurrentCompany,
			stock_id: '',
			previous_year_ending_balance: 0,
			previous_year_ending_cost: 0,
		})
		const { mutate: setPreviousYearsValues } = useMutation(
			SetPreviousYearsAmountsMutation,
			() => ({
				variables: mutationVariables,
			}),
		)
		// ! -----------------------------------------------------------------------------------------
		const onSetPreviousYear = () => {
			dataArray.value.forEach((item) => {
				mutationVariables.stock_id = item.stock_id
				mutationVariables.previous_year_ending_balance = item.inventory_balance
				mutationVariables.previous_year_ending_cost =
					// eslint-disable-next-line use-isnan
					item.inventory_stock_value === NaN ? 0 : item.inventory_stock_value
				setPreviousYearsValues()
			})
			isResetBtnDisabled.value = true
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = true
			isReset2BtnDisabled.value = false
			isFetch2BtnDisabled.value = true
			isSet2BtnDisabled.value = true
		}
		// * -----------------------------------------------------------------------------------------
		const { mutate: reset2Mutation, onDone: onDoneReset2 } = useMutation(
			ResetOutstandingValuesMutation,
			() => ({
				variables: {
					company_id: Store.getters.getUserCurrentCompany,
				},
			}),
		)
		onDoneReset2(() => alert('Outstanding values are reset'))
		// ! -----------------------------------------------------------------------------------------
		const onReset2 = () => {
			reset2Mutation()
			isResetBtnDisabled.value = true
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = true
			isReset2BtnDisabled.value = true
			isFetch2BtnDisabled.value = false
			isSet2BtnDisabled.value = true
		}
		// * -------------------------------------------------------
		const { refetch: refetchPreviousYearsValues } = useQuery(
			GetPreviousYearsValuesQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// * -------------------------------------------------------
		const dataArray2 = ref([])
		const onFetchPreviousYearsValues = () => {
			refetchPreviousYearsValues().then((result) => {
				// console.log('result.data.stock', result.data.stock)
				dataArray2.value = result.data.stock
			})
			isResetBtnDisabled.value = true
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = true
			isReset2BtnDisabled.value = true
			isFetch2BtnDisabled.value = true
			isSet2BtnDisabled.value = false
		}
		// * -----------------------------------------------------------------------------------------
		const mutationVariables2 = reactive({
			company_id: Store.getters.getUserCurrentCompany,
			stock_id: '',
			outstanding_balance: 0,
			outstanding_cost: 0,
		})
		const { mutate: setOutstandingMutation } = useMutation(
			SetOutstandingValuesMutation,
			() => ({
				variables: mutationVariables2,
			}),
		)
		// ! -----------------------------------------------------------------------------------------
		const onSetOutstanding = () => {
			// console.log('dataArray2', dataArray2)
			dataArray2.value.forEach((item) => {
				// console.log('item', item)
				mutationVariables2.stock_id = item.stock_id
				mutationVariables2.outstanding_balance =
					item.previous_year_ending_balance
				mutationVariables2.outstanding_cost = item.previous_year_ending_cost
				// console.log('mutationVariables2', mutationVariables2)
				setOutstandingMutation()
			})
			isResetBtnDisabled.value = false
			isFetchBtnDisabled.value = true
			isSetBtnDisabled.value = true
			isReset2BtnDisabled.value = true
			isFetch2BtnDisabled.value = true
			isSet2BtnDisabled.value = true
			Router.push({ name: 'Startup' })
		}
		return {
			onReset,
			onFetchInventoryValues,
			onSetPreviousYear,
			onReset2,
			onFetchPreviousYearsValues,
			onSetOutstanding,
			isReset2BtnDisabled,
			isFetchBtnDisabled,
			isSetBtnDisabled,
			isResetBtnDisabled,
			isFetch2BtnDisabled,
			isSet2BtnDisabled,
		}
	},
}
</script>
